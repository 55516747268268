import { InputCurrencies } from '@/src/types';

export const defaultReferralMinimum = 3;

export const MYRReferralMinimum = 5;

export const currencies: InputCurrencies[] = [
  {
    code: '🇺🇸 USD',
    symbol: '$',
    min: 1,
    referralMinimum: defaultReferralMinimum,
    presetAmounts: [25, 10, 3],
    placeholder: 10,
  },
  {
    code: '🇨🇦 CAD',
    symbol: '$',
    min: 1,
    referralMinimum: defaultReferralMinimum,
    presetAmounts: [25, 10, 3],
    placeholder: 10,
  },
  {
    code: '🇬🇧 GBP',
    symbol: '£',
    min: 1,
    referralMinimum: defaultReferralMinimum,
    presetAmounts: [25, 10, 3],
    placeholder: 10,
  },
  {
    code: '🇪🇺 EUR',
    symbol: '€',
    min: 1,
    referralMinimum: defaultReferralMinimum,
    presetAmounts: [25, 10, 3],
    placeholder: 10,
  },
  {
    code: '🇲🇾 MYR',
    symbol: 'RM',
    min: 5,
    referralMinimum: MYRReferralMinimum,
    presetAmounts: [50, 30, 10],
    placeholder: 30,
  },
  {
    code: '🇦🇺 AUD',
    symbol: '$',
    min: 1,
    referralMinimum: defaultReferralMinimum,
    presetAmounts: [25, 10, 3],
    placeholder: 10,
  },
  {
    code: '🇸🇬 SGD',
    symbol: '$',
    min: 1,
    referralMinimum: defaultReferralMinimum,
    presetAmounts: [25, 10, 3],
    placeholder: 10,
  },
  {
    code: '🇳🇿 NZD',
    symbol: '$',
    min: 1,
    referralMinimum: defaultReferralMinimum,
    presetAmounts: [25, 10, 3],
    placeholder: 10,
  },
];

export const settingsUrl = '/v4/scheduled-giving/ramadan/settings';

export const homeUrl = '/v4/scheduled-giving/ramadan/home';
export const ramadanHomeUrl = '/scheduled-giving/ramadan/home';
export const dhcHomeUrl = '/v4/scheduled-giving/dhc/home';

export const challengeEnrollUrl = '/v4/scheduled-giving/ramadan?';

export const selectPlanUrl = '/v4/scheduled-giving/ramadan/select-plan?';

export const enterAmountUrl = '/v4/scheduled-giving/ramadan/enter-amount?';

export const paymentSelectionUrl =
  '/v4/scheduled-giving/ramadan/payment-selection?';

export const reviewUrl = '/v4/scheduled-giving/ramadan/review?';

export const confirmationUrl =
  '/v4/scheduled-giving/ramadan/confirmation-screen?';

export const eidMubarakUrl = '/v4/eid';

export const qurbaniUrl = '/v4/qurbani';
