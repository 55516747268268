import { CampaignBasics, Card, CardTypeLogos, PlanInfo } from '@/src/types';

export const cardTypeLogos: CardTypeLogos = {
  visa: 'https://launchgood.s3.amazonaws.com/static/icons/card-types/visa.svg',
  mastercard:
    'https://launchgood.s3.amazonaws.com/static/icons/card-types/mastercard.svg',
  master:
    'https://launchgood.s3.amazonaws.com/static/icons/card-types/mastercard.svg',
  amex: 'https://launchgood.s3.amazonaws.com/static/icons/card-types/amex.svg',
  american:
    'https://launchgood.s3.amazonaws.com/static/icons/card-types/amex.svg',
  discover:
    'https://cdn.builder.io/api/v1/image/assets%2Fc05b786f1645447ab878b73ca4dd6870%2F02843511d42f48819854eda130643731',
  default:
    'https://cdn.builder.io/api/v1/image/assets%2Fc05b786f1645447ab878b73ca4dd6870%2Fd0260b4d343a44b389b6bfe74aaa6f34',
};

export const emptyCard: Card = {
  id: 1,
  description: '',
  simpleDescription: '',
  giftAid: false,
  tokens: 0,
  tokenTypes: [],
  default: false,
  expired: false,
  expirationDate: '',
  typeEnum: '',
  address: {
    id: 0,
    name: '',
    phone: '',
    address: '',
    address2: '',
    country: '',
    city: '',
    zip: '',
    state: '',
    save: false,
  },
  location: '',
};

export const TIP_CUSTOM = 'custom';

export const emptyCampaignBasics: CampaignBasics = {
  id: 0,
  slug: '',
  userId: 0,
  name: '',
  imageUrl: '',
};

export const dhulHijjahChallenge = 2;
export const fridayGivers = 3;
export const ramadanChallange30Days = 4;
export const ramadanChallange10Days = 5;

export const futureChallengeDhcHeading = 'Dhul Hijjah Challenge';
export const futureChallengeFridayGiversHeading = 'Friday Givers';
export const futureChallengeRamadanHeading = 'Ramadan Challenge';

export const planInfoInit: PlanInfo = {
  id: 0,
  name: '',
  startDate: '',
  endDate: '',
  givingPlanDay: 0,
  daysToGivingPlan: 0,
  signedUp: 0,
  newMembers: 0,
};
